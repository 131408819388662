import "../styles/App.css"
import "../styles/About.css"

export default function About() {


    return (
        <>
            <div className='About-section' >
                <p className='About-me Sub-heading' >About me</p> 
                <p className='About-text'>
                Welcome to my page.  I've invested a lot of my time into app development and hackathons which I utilized to collaborate on iOS projects centered around thrilling, new ideas! As I move forward,
                I'm looking to expand and apply my algorithmic capabilities, learn new technologies, while providing impactful software. 
                <br />
                <br/>
                From Kuwait.
                </p>
            </div>
        </>
    )
}
